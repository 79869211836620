@keyframes hideAfterDelay {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@keyframes fadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.no-spinner {
  -moz-appearance: textfield;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div, p, h1, h2, h3, h4, h5 {
  margin: 0;
}

div, p, h1, h2, h3, h4, h5, span {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

svg {
  letter-spacing: normal;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
}

.col-20-pct {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-40-pct {
  flex: 0 0 40%;
  max-width: 40%;
}

.col-60-pct {
  flex: 0 0 60%;
  max-width: 60%;
}

.col-80-pct {
  flex: 0 0 80%;
  max-width: 80%;
}

@media (min-width: 576px) {
  .col-sm-2p4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 768px) {
  .col-md-2p4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 992px) {
  .col-lg-2p4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.clickable {
  cursor: pointer;
}

.ml-20 {
  margin-left: 20px;
}

.bg-white {
  background: #FFFFFF;
}

.bg-grey {
  background: #E9E9E9;
}

.colors .darkred {
  color: darkred;
}
.colors .darkgreen {
  color: darkgreen;
}

.cbb-box-shadowed {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.425), 0 1px 5px 0 rgba(0, 0, 0, 0.425), 0 3px 1px -2px rgba(0, 0, 0, 0.425);
}

.shadow-container {
  margin: 0px auto;
  padding: 0px 0px 20px 0px;
  max-width: 100%;
  background-color: white;
  position: relative;
}

.padded-inner-container {
  max-width: 1620px;
  padding: 25px 20px;
}
@media (max-width: 1199.98px) {
  .padded-inner-container {
    padding: 20px 15px;
  }
}
@media (max-width: 991.98px) {
  .padded-inner-container {
    padding: 20px 13px;
  }
}
@media (max-width: 767.98px) {
  .padded-inner-container {
    padding: 20px 11px;
  }
}
@media (max-width: 575.98px) {
  .padded-inner-container {
    padding: 20px 10px;
  }
}

.tool-container {
  padding-top: 15px;
  padding-bottom: 5px;
  background: #CCCCCC;
  border-bottom: 1px solid #222222;
  max-width: none;
}

.home-page-container {
  max-width: 100%;
  padding: 75px 25px;
}
@media (max-width: 1199.98px) {
  .home-page-container {
    padding: 60px 25px;
  }
}
@media (max-width: 991.98px) {
  .home-page-container {
    padding: 50px 22px;
  }
}
@media (max-width: 767.98px) {
  .home-page-container {
    padding: 45px 20px;
  }
}
@media (max-width: 575.98px) {
  .home-page-container {
    padding: 40px 18px;
  }
}

.hero-container {
  min-width: 100%;
  padding: 0;
  position: relative;
  text-align: center;
}
.hero-container .hero-image-container {
  overflow: hidden;
}
.hero-container .hero-image-container .hero-image {
  width: 100%;
  min-width: 1200px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .hero-container .hero-image-container .hero-image {
    left: -50px;
  }
}
@media (max-width: 991.98px) {
  .hero-container .hero-image-container .hero-image {
    left: -100px;
  }
}
@media (max-width: 767.98px) {
  .hero-container .hero-image-container .hero-image {
    left: -200px;
  }
}
.hero-container .hero-content-container {
  position: absolute;
  padding: 0px 40px;
  top: 20%;
  right: 5%;
  max-width: 750px;
}
@media (max-width: 1199.98px) {
  .hero-container .hero-content-container {
    top: 12%;
  }
}
@media (max-width: 991.98px) {
  .hero-container .hero-content-container {
    top: 8%;
  }
}
@media (max-width: 767.98px) {
  .hero-container .hero-content-container {
    top: 5%;
  }
}
.hero-container .hero-content-container .hero-header, .hero-container .hero-content-container .hero-subheader {
  text-align: left;
  color: #dddddd;
  z-index: 50;
  font-size: 1.4em;
  font-family: "Roboto Slab";
}
.hero-container .hero-content-container .hero-header {
  font-size: calc(48px + 57 * (100vw - 400px) / 1200);
  font-weight: 700;
  font-family: "Roboto Slab";
  line-height: 1em;
  margin-bottom: 100px;
}
@media (max-width: 1199.98px) {
  .hero-container .hero-content-container .hero-header {
    margin-bottom: 60px;
  }
}
@media (max-width: 991.98px) {
  .hero-container .hero-content-container .hero-header {
    margin-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  .hero-container .hero-content-container .hero-header {
    margin-bottom: 20px;
  }
}

.contact-us-form {
  z-index: 9001;
  background: #DDD;
  border: 1px solid #222222;
  border-radius: 5px;
  margin: 15px auto 0 auto;
  font-size: 0.9em;
  overflow: hidden;
}
.contact-us-form.footer-form {
  position: absolute;
  right: 1%;
  bottom: 110%;
  width: 400px;
}
.contact-us-form.homepage-form {
  width: 1190px;
  max-width: 100%;
  margin-bottom: 80px;
}
.contact-us-form .contact-us-form__header {
  text-align: left;
  color: #0066CC;
  font-weight: 700;
  opacity: 1;
  padding: 12px 20px 20px 20px;
}
.contact-us-form .contact-us-form__header p.p1 {
  font-size: 1.3em;
  font-weight: 700;
  margin-bottom: 5px;
}
.contact-us-form .contact-us-form__header p.p2 {
  font-size: 1.1em;
  line-height: 1.12em;
  margin-top: 12px;
}
.contact-us-form .form-row {
  margin-bottom: 2px;
  padding: 0px 20px;
}
.contact-us-form .form-control {
  font-size: 0.95rem;
}
.contact-us-form .form-group {
  margin-bottom: 0.6rem;
}
.contact-us-form label.form-label {
  color: #222222;
  margin-bottom: 0;
  float: left;
  line-height: 1.1em;
  padding-left: 5px;
}
.contact-us-form input.cbb-home-input, .contact-us-form textarea.cbb-home-input {
  border: 1px solid #888888;
}
.contact-us-form input.cbb-home-input:focus, .contact-us-form textarea.cbb-home-input:focus {
  border: 1px solid #0066CC;
  box-shadow: 0 2px 2px 0 rgba(0, 102, 204, 0.14), 0 1px 5px 0 rgba(0, 102, 204, 0.12), 0 3px 1px -2px rgba(0, 102, 204, 0.2);
}
.contact-us-form input.cbb-home-input:hover, .contact-us-form textarea.cbb-home-input:hover {
  border: 1px solid #0066CC;
  box-shadow: 0 2px 2px 0 rgba(0, 102, 204, 0.14), 0 1px 5px 0 rgba(0, 102, 204, 0.12), 0 3px 1px -2px rgba(0, 102, 204, 0.2);
}
.contact-us-form button.btn {
  float: left;
  padding: 7px 18px;
  background-color: #0066CC;
  border-color: #0066CC;
}
.contact-us-form button.btn:hover {
  background-color: #0052a5;
  border-color: #0052a5;
}
.contact-us-form .btn:disabled {
  background-color: #cc0000;
  border-color: #cc0000;
  cursor: not-allowed;
}

div.about-us-hero-row {
  background: black;
  position: relative;
}
div.about-us-hero-row h3 {
  font-family: "Roboto Slab";
  font-weight: 700;
  font-size: 48px;
}
div.about-us-hero-row .abous-us-hero-lhs, div.about-us-hero-row .abous-us-hero-rhs {
  padding: 0;
  height: inherit;
  z-index: 2;
}
div.about-us-hero-row .abous-us-hero-rhs {
  display: flex;
  align-items: center;
}
div.about-us-hero-row .abous-us-hero-lhs {
  color: white;
}
div.about-us-hero-row .steph-klay-image {
  height: 250px;
  width: 100%;
  background-repeat: repeat-x;
}

.toggle-note-button {
  background: none;
  border: none;
  text-align: left;
  margin-top: 10px;
  padding: 0px;
  line-height: 1.3;
  font-size: 1.1em;
}
.toggle-note-button:hover {
  border-radius: 5px;
  background: #ddd;
}

.cbb-tooltip {
  z-index: 50;
  line-height: 1.4;
  position: absolute;
  padding: 5px 18px 5px 5px;
  font-size: 0.85em;
  border: 0px;
  border-radius: 5px;
  border: 1px solid #222;
  pointer-events: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.425), 0 1px 5px 0 rgba(0, 0, 0, 0.425), 0 3px 1px -2px rgba(0, 0, 0, 0.425);
}
.cbb-tooltip p {
  margin: 0 0 2px 0;
}
.cbb-tooltip.black .underline {
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
  padding-bottom: 3px;
}
.cbb-tooltip.white .underline {
  border-bottom: 1px solid white;
  margin-bottom: 5px;
  padding-bottom: 3px;
}
.cbb-tooltip h3, .cbb-tooltip h4 {
  margin: 0 0 3px 0;
  font-size: 1.15em;
  font-weight: 700;
}

.table-tip {
  z-index: 999;
  position: absolute;
  text-align: left;
  width: 375px;
  font-family: "futura-pt", sans-serif;
  font-size: 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.425), 0 1px 5px 0 rgba(0, 0, 0, 0.425), 0 3px 1px -2px rgba(0, 0, 0, 0.425);
  border-radius: 2px;
  pointer-events: none;
}
.table-tip .table-tip__title {
  background-color: #DDD;
  border-bottom: 1px solid #BBB;
  border-bottom: none;
  font-size: 16px;
  color: #333333;
  padding: 3px 6px;
  font-weight: bold;
}
.table-tip .table-tip__desc {
  background-color: #FFF;
  font-size: 14px;
  line-height: 1.4;
  color: #333333;
  padding: 6px;
  min-height: 50px;
}

.top-navbar {
  position: sticky;
  padding: 0;
  background: #444;
  height: 48px;
}
.top-navbar .navbar-brand-wrapper {
  width: 175px;
  height: 48px;
  display: flex;
  align-items: center;
}
.top-navbar .navbar-brand-wrapper .navbar-brand {
  margin: 0 15px;
  padding: 2px 0px;
  width: 152px;
}
.top-navbar .navbar-search-wrapper {
  display: flex;
  justify-content: flex-end;
}
.top-navbar .navbar-search-wrapper .app-select-wrapper {
  width: 300px;
}
@media (max-width: 991px) {
  .top-navbar .navbar-search-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
.top-navbar button.cbb-hamburger {
  background: #aaa;
  height: 48px;
  width: 64px;
  z-index: 20;
}
.top-navbar button.cbb-hamburger.collapsed {
  margin-top: 0px;
}
.top-navbar button.cbb-hamburger:hover {
  background: #ccc;
}
.top-navbar button.cbb-hamburger span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 102, 204, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 1.5em;
  height: 1.5em;
}
.top-navbar .navbar-collapse {
  justify-content: space-between;
}
@media (max-width: 991px) {
  .top-navbar .navbar-collapse {
    background: #AAAAAA;
    max-height: calc(100vh - 48px);
    overflow: scroll;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
.top-navbar .navbar-collapse .navbar-toggler.collapsed {
  border: none;
}
.top-navbar .navbar-collapse .navbar-toggler.collapsed span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 102, 204, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.top-navbar .navbar-collapse .dropdown-menu {
  margin-top: -2px;
  background-color: #444;
  padding: 4px 0px;
}
@media (max-width: 991px) {
  .top-navbar .navbar-collapse .dropdown-menu {
    text-align: center;
  }
}
.top-navbar .navbar-collapse .dropdown-menu .dropdown-item {
  line-height: 1.4;
  padding: 3px 8px;
  font-size: 1.25em;
  border-left: 5px solid #444;
  color: white;
}
.top-navbar .navbar-collapse .dropdown-menu .dropdown-item:hover, .top-navbar .navbar-collapse .dropdown-menu .dropdown-item:focus {
  border-left: 5px solid #0066CC;
  background-color: #777;
}
.top-navbar .navbar-collapse .dropdown:hover .dropdown-menu {
  display: block;
}
.top-navbar .navbar-collapse .dropdown-toggle.nav-link:after {
  display: none;
}
.top-navbar .navbar-nav {
  align-items: center;
}
@media (max-width: 991px) {
  .top-navbar .navbar-nav {
    text-align: center;
  }
}
.top-navbar .navbar-nav.center-nav {
  margin-right: -40px;
}
@media (max-width: 991px) {
  .top-navbar .navbar-nav.center-nav {
    margin-right: 0px;
  }
}
@media (max-width: 991px) {
  .top-navbar .navbar-nav .nav-item {
    padding: 4px 0px;
  }
  .top-navbar .navbar-nav .nav-item.dropdown {
    width: 100%;
    padding: 0px;
  }
}
.top-navbar .navbar-nav .nav-item.selected {
  background: #0066CC;
  border-radius: 5px;
  border: 1px solid #333333;
  color: white;
}
.top-navbar .navbar-nav .nav-item .stats-dropdown a.nav-link {
  color: white;
}
.top-navbar .navbar-nav .nav-item.show a.nav-link {
  color: white;
}
.top-navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  padding: 2px 5px;
  margin: 0px 4px;
}
@media (max-width: 991px) {
  .top-navbar .navbar-nav .nav-link {
    font-size: 1.25em;
    padding: 4px 0px;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .top-navbar .navbar-nav .nav-link {
    margin: 0;
  }
}
.top-navbar .navbar-nav .nav-link:hover {
  background: #0066CC;
  border-radius: 5px;
  color: white;
}
.top-navbar .navbar-nav .nav-link:focus {
  border-radius: 5px;
  color: white;
}
.top-navbar .navbar-nav .nav-link.orange {
  text-decoration: underline #CC6600;
  text-decoration-style: double;
}
.top-navbar .navbar-nav .nav-link.selected {
  background: #0066CC;
  border-radius: 5px;
  border: 1px solid #333333;
  color: white;
}
.top-navbar .navbar-nav .nav-link.selected.orange {
  background: #CC6600;
  text-decoration: underline #0066CC;
  text-decoration-style: double;
}
.top-navbar .navbar-nav .nav-link .stats-dropdown a {
  color: white;
}
.top-navbar .signup-login-nav {
  margin-right: 35px;
}
@media (max-width: 991px) {
  .top-navbar .signup-login-nav {
    margin-right: 10px;
  }
}
.top-navbar .signup-login-nav .nav-link {
  font-size: 1.05em;
}
.top-navbar .signup-login-nav .nav-link.redirect-to-signup-button {
  padding: 4px calc(25px + 1.75vw) 4px calc(25px + 1.75vw);
  font-weight: 700;
  border-radius: 3px;
  margin-right: 20px;
  background: #CC6600;
  text-align: center;
  color: white;
  height: 100%;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .top-navbar .signup-login-nav .nav-link.redirect-to-signup-button {
    margin-right: 10px;
  }
}
.top-navbar .signup-login-nav .nav-link.login-button {
  padding: 4px 10px;
}

.controller-navbar-container {
  min-height: calc(30px + 4vw);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #222222;
  padding: 0px 16px;
  position: sticky;
  top: 48px;
  z-index: 25;
}
@media (max-width: 1199.98px) {
  .controller-navbar-container {
    padding: 0px 11px;
  }
}
@media (max-width: 991.98px) {
  .controller-navbar-container {
    padding: 0px 8px;
  }
}
@media (max-width: 767.98px) {
  .controller-navbar-container {
    padding: 0px 5px;
  }
}
@media (max-width: 575.98px) {
  .controller-navbar-container {
    padding: 0px 3px;
  }
}
.controller-navbar-container nav.navbar .navbar-toggler {
  padding: 0.1em;
  padding-top: 0;
  margin-right: 5px;
  width: 50px;
  height: 40px;
  border-color: #444;
  background: #aaa;
}
.controller-navbar-container nav.navbar .navbar-toggler:hover {
  background: #ccc;
}
.controller-navbar-container nav.navbar .navbar-toggler:focus {
  outline: 1px solid white;
}
.controller-navbar-container nav.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 102, 204, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 1.5em;
  height: 1.5em;
}
.controller-navbar-container div.header-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 1px;
  margin-top: calc(0px + 0.3vw);
}
@media (max-width: 991px) {
  .controller-navbar-container div.header-wrapper {
    flex-direction: row;
  }
}
.controller-navbar-container .header {
  color: white;
  float: left;
  margin-bottom: calc(0px + 0.5vw);
  margin-right: 8px;
  font-weight: 700;
  font-size: calc(12px + 1.4vw);
  line-height: 1;
}
.controller-navbar-container .header.pad {
  padding-left: 160px;
}
.controller-navbar-container .win-loss-label {
  color: white;
  float: left;
  margin-top: -2px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  margin-right: 8px;
  font-weight: 700;
  font-size: calc(9px + 0.6vw);
}
.controller-navbar-container .win-loss-label.pad {
  padding-left: 160px;
}
.controller-navbar-container .game-lhs-col, .controller-navbar-container .game-rhs-col {
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.controller-navbar-container .game-lhs-col {
  justify-content: flex-end;
  padding-right: calc(1px + 1.5vw);
  text-align: right;
}
.controller-navbar-container .game-rhs-col {
  justify-content: flex-start;
  padding-left: calc(1px + 1.5vw);
}
.controller-navbar-container .game-header, .controller-navbar-container .game-score, .controller-navbar-container .game-record, .controller-navbar-container .game-date {
  color: white;
  margin-bottom: calc(1px + 0.3vw);
  font-weight: 700;
  font-size: calc(10px + 0.9vw);
  line-height: 1;
}
.controller-navbar-container .game-header.pad, .controller-navbar-container .game-score.pad, .controller-navbar-container .game-record.pad, .controller-navbar-container .game-date.pad {
  padding-left: 160px;
}
.controller-navbar-container .game-date {
  margin-bottom: 0;
}
.controller-navbar-container .game-record {
  font-size: calc(10px + 0.7vw);
}
.controller-navbar-container .game-score {
  font-size: calc(16px + 1.6vw);
  margin: 0px calc(0px + 1.5vw);
}
.controller-navbar-container .game-date {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: calc(10px + 0.7vw);
  padding-right: 24px;
}
.controller-navbar-container div.buttons {
  margin-top: 6px;
}
.controller-navbar-container div.buttons.collapsing {
  display: none;
}
@media (max-width: 991px) {
  .controller-navbar-container div.buttons.game-buttons.collapse.show {
    transition: width 2s, transform 2s;
    background: #666;
    border-radius: 5px;
    border: 1px solid #222222;
    padding: 3px 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 50;
    left: 0;
    top: 37px;
    width: 225px;
    align-items: flex-start;
  }
  .controller-navbar-container div.buttons.game-buttons.collapse.show .button {
    width: 100%;
    text-align: center;
    margin-bottom: calc(2px + 0.2vw);
    padding: 3px 4px 4px 4px;
    font-size: 18px;
    max-height: 29px;
  }
  .controller-navbar-container div.buttons.game-buttons.collapse.show .button:hover {
    border-radius: 5px;
    background: white;
    color: #444444;
  }
}
.controller-navbar-container div.buttons .button {
  max-height: 25px;
  cursor: pointer;
  display: inline-block;
  color: white;
  font-size: calc(9px + 0.52vw);
  margin: 0 calc(2px + 0.05vw) 0.05vw 0;
  padding: 0 3px;
  line-height: 1.3;
  transition: 0.1s;
  border-radius: 4px;
  text-align: center;
}
.controller-navbar-container div.buttons .button:hover {
  background: rgba(255, 255, 255, 0.75);
  text-decoration-color: white;
}
.controller-navbar-container div.buttons .button.selected {
  background-color: white;
  color: #444444;
}
.controller-navbar-container div.buttons .button.disabled {
  opacity: 0.25;
  text-decoration: line-through;
}
.controller-navbar-container h3.game-date-label {
  position: absolute;
  bottom: 0%;
  right: 0%;
  margin-right: 20px;
  margin-bottom: 0px;
  color: white;
  font-size: 1.15em;
}
.controller-navbar-container div.vs-circle {
  position: absolute;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  top: 42%;
  border: 2px solid #222222;
  font-size: 36px;
  border-radius: 50%;
  overflow: hidden;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.controller-navbar-container .show-big {
  display: inline;
}
@media (max-width: 991px) {
  .controller-navbar-container .show-big {
    display: none;
  }
}
.controller-navbar-container .show-small {
  display: none;
}
@media (max-width: 991px) {
  .controller-navbar-container .show-small {
    display: inline;
  }
}

.comps-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -12px;
}
.comps-header-wrapper .left-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.comps-header-wrapper .left-section .number-circle {
  font-size: 1.1em;
  width: 44px;
  height: 44px;
  padding: 6px 0px;
}
@media (max-width: 1199.98px) {
  .comps-header-wrapper .left-section .number-circle {
    width: 39px;
    height: 39px;
    font-size: 0.96em;
  }
}
@media (max-width: 991.98px) {
  .comps-header-wrapper .left-section .number-circle {
    width: 32px;
    height: 32px;
    font-size: 0.83em;
    padding-top: 4px;
  }
}
@media print {
  .comps-header-wrapper .left-section .number-circle {
    width: 47px;
    height: 47px;
    font-size: 1.25em;
    padding-top: 6px;
  }
}
.comps-header-wrapper .left-section .sim-score-player-name {
  margin: 0 0 0 4px;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 1.2;
}
@media (max-width: 1199.98px) {
  .comps-header-wrapper .left-section .sim-score-player-name {
    font-size: 0.96em;
  }
}
@media (max-width: 991.98px) {
  .comps-header-wrapper .left-section .sim-score-player-name {
    font-size: 0.88em;
  }
}
@media (max-width: 767.98px) {
  .comps-header-wrapper .left-section .sim-score-player-name {
    font-size: 1.02em;
  }
}
@media (max-width: 575.98px) {
  .comps-header-wrapper .left-section .sim-score-player-name {
    font-size: 0.94em;
  }
}
@media print {
  .comps-header-wrapper .left-section .sim-score-player-name {
    font-size: 1.175em;
  }
}
.comps-header-wrapper .left-section .sim-score-player-name .link {
  color: #222222;
  text-decoration: underline;
}
.comps-header-wrapper .right-section {
  display: flex;
  align-items: center;
}
.comps-header-wrapper .right-section .player-image {
  margin-left: 2px;
  width: 44px;
  max-height: 44px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #222;
}
@media (max-width: 1199.98px) {
  .comps-header-wrapper .right-section .player-image {
    width: 38px;
    max-height: 38px;
  }
}
@media (max-width: 991.98px) {
  .comps-header-wrapper .right-section .player-image {
    width: 31px;
    max-height: 31px;
  }
}
@media print {
  .comps-header-wrapper .right-section .player-image {
    width: 44px;
    max-height: 44px;
  }
}
.comps-header-wrapper .right-section .comps-header-team-logo {
  margin-left: 2px;
  width: 44px;
  max-height: 44px;
  padding: 2px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #222;
}
@media (max-width: 1199.98px) {
  .comps-header-wrapper .right-section .comps-header-team-logo {
    width: 38px;
    max-height: 38px;
  }
}
@media (max-width: 991.98px) {
  .comps-header-wrapper .right-section .comps-header-team-logo {
    width: 31px;
    max-height: 31px;
  }
}
@media print {
  .comps-header-wrapper .right-section .comps-header-team-logo {
    width: 44px;
    max-height: 44px;
  }
}

div.logos-wrapper {
  display: flex;
  align-self: center;
  background: #FFFFFF;
  padding: 0.4vw 0.8vw;
  border: 1px solid #222222;
  border-top: none;
  margin-bottom: -6px;
}
div.logos-wrapper div.logo-sticker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: calc(26px + 6vw);
  max-height: 100px;
  width: calc(26px + 6vw);
  max-width: 100px;
}
div.logos-wrapper div.logo-sticker:hover {
  background: #999999;
}

.mini-game-card-navbar-wrapper {
  margin: 0;
  border-bottom: 1px solid #222222;
  justify-content: space-between;
  padding: 5px 20px;
}
@media (max-width: 1199.98px) {
  .mini-game-card-navbar-wrapper {
    padding: 5px 15px;
  }
}
@media (max-width: 991.98px) {
  .mini-game-card-navbar-wrapper {
    padding: 5px 13px;
  }
}
@media (max-width: 767.98px) {
  .mini-game-card-navbar-wrapper {
    padding: 4px 11px;
  }
}
@media (max-width: 575.98px) {
  .mini-game-card-navbar-wrapper {
    padding: 3px 10px;
  }
}

.dropdown-row {
  margin: 0 auto;
  max-width: 1620px;
  padding: 0px 20px;
}
@media (max-width: 1199.98px) {
  .dropdown-row {
    padding: 0px 15px;
  }
}
@media (max-width: 991.98px) {
  .dropdown-row {
    padding: 0px 13px;
  }
}
@media (max-width: 767.98px) {
  .dropdown-row {
    padding: 0px 11px;
  }
}
@media (max-width: 575.98px) {
  .dropdown-row {
    padding: 0px 10px;
  }
}

.mini-game-card-info {
  display: flex;
  align-items: center;
}

.mini-game-card-navbar {
  min-height: 41px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.mini-game-card-navbar a.mini-game-card {
  display: flex;
  position: relative;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.32);
  margin: 2px 1px;
  color: #333333;
  cursor: pointer;
}
.mini-game-card-navbar a.mini-game-card:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  position: relative;
  top: -2px;
  left: -2px;
  text-decoration: none;
}
.mini-game-card-navbar a.mini-game-card.hidden-dark {
  color: white;
  background: #444444;
}
.mini-game-card-navbar a.mini-game-card.hidden-light {
  color: white;
  background: #333333;
}
.mini-game-card-navbar a.mini-game-card .card-col-1 {
  display: flex;
  flex-direction: column;
  margin: 1px;
  padding: 1px 1px;
}
.mini-game-card-navbar a.mini-game-card .card-col-1 img {
  max-width: 19px;
  max-height: 19px;
  display: block;
  margin: 0 auto;
}
.mini-game-card-navbar a.mini-game-card .card-col-1 span {
  font-size: 9px;
}
@media (max-width: 991.98px) {
  .mini-game-card-navbar a.mini-game-card .card-col-1 {
    margin: 0px 1px;
    padding: 0px;
  }
  .mini-game-card-navbar a.mini-game-card .card-col-1 img {
    max-width: 13px;
    max-height: 13px;
  }
  .mini-game-card-navbar a.mini-game-card .card-col-1 span {
    font-size: 8px;
  }
}
.mini-game-card-navbar a.mini-game-card div.mini-game-card-tip {
  position: absolute;
  bottom: 110%;
  left: -25%;
  max-width: 275px;
  width: max-content;
  font-size: 0.8em;
  background: #EEEEEE;
  color: #222;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.425), 0 1px 5px 0 rgba(0, 0, 0, 0.425), 0 3px 1px -2px rgba(0, 0, 0, 0.425);
  padding: 3px;
  z-index: 5000;
}

.my-account-container {
  max-width: 100%;
}
.my-account-container .my-account-button-row {
  justify-content: center;
  background: #CCC;
  padding-top: 90px;
}
@media (max-width: 575.98px) {
  .my-account-container .my-account-button-row {
    padding-top: 20px;
  }
}
.my-account-container .my-account-inner-container {
  max-width: 750px;
  padding: 0px;
  margin-top: 40px;
}

.app-footer-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #444;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.425), 0 1px 5px 0 rgba(0, 0, 0, 0.425), 0 3px 1px -2px rgba(0, 0, 0, 0.425);
  margin-top: auto;
  clear: both;
  font-size: 17px;
  color: white;
  text-align: center;
  padding: 10px 0px;
}
.app-footer-container p {
  margin: 0;
}
.app-footer-container .livestats-logo-wrapper {
  max-width: calc(100px + 6vw);
  margin-left: calc(2px + 0.5vw);
}
.app-footer-container .livestats-logo-wrapper .livestats-logo {
  width: 100%;
  border-radius: 5px;
}
.app-footer-container .footer-links {
  color: white;
  font-size: calc(14px + 0.32vw);
  line-height: 1.3;
}
.app-footer-container .chat-button-wrapper {
  width: 70px;
  margin: -4px 3px -4px 0px;
  width: calc(47px + 1.9vw);
}
.app-footer-container .chat-button-wrapper .chat-button {
  padding: 1px 3px;
  cursor: pointer;
}
.app-footer-container .chat-button-wrapper .chat-button .chat-icon {
  fill: rgb(0, 102, 204);
}
.app-footer-container .chat-button-wrapper .chat-button .chat-icon:hover {
  fill: rgba(0, 102, 204, 0.75);
}
.app-footer-container .chat-button-wrapper .chat-button svg {
  margin: -4px 0px;
  width: calc(35px + 1.9vw);
  height: calc(35px + 1.9vw);
}

a.conference-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 170px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: white;
  text-decoration: none;
  color: #222222;
  padding: 8px 8px 10px 8px;
  margin: 0 15px 15px 0;
  border: 1px solid #CCCCCC;
}
a.conference-card:hover {
  background-color: #eee;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  position: relative;
  top: -2px;
  left: -2px;
}
a.conference-card img {
  max-width: 140px;
  max-height: 65px;
  display: block;
  margin: 0 auto;
}
a.conference-card p.text-below {
  margin: 0;
  margin-top: 10px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.1;
}

a.scoreboard-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 18%;
  min-width: 250px;
  background-color: white;
  text-decoration: none;
  color: #222222;
  padding: 4px 0px 4px 6px;
  margin: 0 25px 25px 0;
  background: #F5F5F5;
  border-radius: 5px;
  border: 1px solid #333;
}
a.scoreboard-card:hover {
  background-color: #eee;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  position: relative;
  top: -2px;
  left: -2px;
}
a.scoreboard-card h3.card-title {
  margin: 5px;
  margin-bottom: 12px;
  font-size: 0.81em;
  color: #666;
}
a.scoreboard-card h3.card-title .header-span {
  font-weight: 700;
}
a.scoreboard-card h3.card-title .header-span.darkred {
  color: darkred;
}
a.scoreboard-card h3.card-title .header-span.darkgreen {
  color: darkgreen;
}
a.scoreboard-card h3.card-title .header-span.darkorange {
  color: #e67e00;
}
a.scoreboard-card h3.card-title .header-span.darkgrey {
  color: #666;
}
a.scoreboard-card h3.team-title {
  font-weight: 700;
  margin: 0px 0px 0px 5px;
  line-height: 1.1;
  font-size: 0.91em;
}
a.scoreboard-card h3.team-title p {
  font-weight: 300;
  font-size: 0.78em;
  color: #666;
  margin-top: 1px;
}
a.scoreboard-card img {
  max-width: 140px;
  max-height: 65px;
  display: block;
  margin: 0 auto;
}
a.scoreboard-card p.text-below {
  margin: 0;
  margin-top: 10px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.1;
}

.triangle-left {
  position: absolute;
  right: 0%;
  border-top: 7px solid transparent;
  border-right: 7px solid #333333;
  border-bottom: 7px solid transparent;
}
.triangle-left.hidden {
  display: none;
}

.line-score-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.425), 0 1px 5px 0 rgba(0, 0, 0, 0.425), 0 3px 1px -2px rgba(0, 0, 0, 0.425);
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 40%;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 1.3em;
}
.line-score-card .inner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.line-score-card .inner-content .card-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  padding: 4px 0px;
}
.line-score-card .inner-content .card-row .card-value {
  flex: 1;
  text-align: center;
}
.line-score-card .inner-content .card-row .card-value.score-total {
  font-weight: 700;
}

.table-header-1 {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.05;
}
.table-header-1 span {
  font-size: 18px;
  font-weight: 300;
  padding-left: 5px;
}

.section-header-1 {
  font-weight: 700;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  line-height: 1;
  font-size: calc(18px + 0.7vw);
}
.section-header-1 span {
  font-size: 0.8em;
}
.section-header-1.sm {
  font-size: calc(18px + 0.5vw);
}
.section-header-1.bp-sizing-1 {
  font-size: 31px;
}
@media (max-width: 1199.98px) {
  .section-header-1.bp-sizing-1 {
    font-size: 26px;
  }
}
@media (max-width: 991.98px) {
  .section-header-1.bp-sizing-1 {
    font-size: 31px;
  }
}

.select-header {
  font-size: 0.85em;
  color: #333333;
  margin-bottom: -2px;
}

.form-error-header {
  color: #8b0000;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 0.95em;
}

.modal-alert {
  max-width: 325px;
  width: 100%;
  border: 1px solid #222;
  text-align: "center";
  padding: 8px 20px;
}

.footer-alert {
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  border-top: 1px solid #222222;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  height: 45px;
  position: fixed;
  bottom: 0%;
  color: white;
  background: #0066CC;
}
.footer-alert .footer-wrapper-div p {
  font-size: 1.15em;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  .footer-alert .footer-wrapper-div p {
    font-size: 0.925em;
  }
}
.footer-alert div.alert-x {
  margin-top: 5px;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 1.5em;
  font-family: fantasy;
  cursor: pointer;
}
.footer-alert.hidden {
  display: none;
  height: 0;
}
.footer-alert.hidden p {
  display: none;
}
.footer-alert.hidden div.alert-x {
  display: none;
}

p.large-select-text {
  position: absolute;
  pointer-events: none;
  margin: 8px 0 0 10px;
  font-size: 0.85em;
  color: #333333;
  z-index: 50;
}
p.large-select-text.disabled {
  color: #DDDDDD;
}

.cbb-policy-top-header {
  margin: 25px 0px 5px 0px;
  font-weight: 700;
  font-size: 2em;
}

.cbb-policy-div .cbb-policy-header {
  margin: 22px 0px 2px 0px;
  font-weight: 700;
  font-size: 1.45em;
}
.cbb-policy-div p {
  margin-bottom: 10px;
  font-size: 0.935em;
}

a.logo img {
  width: 100%;
}

.shot-chart-styles-toggle {
  position: absolute;
  top: 0;
  left: -20px;
  z-index: 200;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  fill: #CCC;
  border: 1px solid #888;
}
.shot-chart-styles-toggle:hover {
  fill: #888;
}

.shot-charts-app-style-button {
  position: absolute;
  z-index: 200;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background-color: #F2F2F2;
  border: 1px solid #888;
}
.shot-charts-app-style-button:hover {
  background-color: #BBB;
}

.cbb-radio-button-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.cbb-radio-button {
  background: white;
  margin-top: 3px;
  margin-right: 0px;
  border: 1px solid #222222;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-size: 0.9em;
  color: #222222;
  padding: 0px 5px;
  transition: 0.1s;
}
.cbb-radio-button:hover {
  background: rgba(0, 102, 204, 0.5);
}
.cbb-radio-button.selected {
  background: rgba(0, 102, 204, 0.5);
}

.filter-icon-button {
  border: 1px solid #888;
  border-radius: 5px;
  padding: 0px 8px;
  background: white;
}
.filter-icon-button:hover {
  background: rgba(0, 102, 204, 0.2);
}
.filter-icon-button:focus {
  box-shadow: none;
}
.filter-icon-button.selected {
  border: 1px solid #0066CC;
}

.filter-remove-trashcan {
  border: 1px solid #888;
  background: white;
  padding: 0px 4px 2px 4px;
  z-index: 1;
}
.filter-remove-trashcan svg {
  fill: darkred;
}
.filter-remove-trashcan:hover {
  background: rgba(139, 0, 0, 0.15);
}
.filter-remove-trashcan:focus {
  box-shadow: 0 0 1px #0066CC;
  border: 1px solid #0066CC;
  z-index: 100;
}
.filter-remove-trashcan.selected {
  border: 1px solid #0066CC;
}

.filter-submit-button {
  border: 1px solid #888;
  border-radius: 5px;
  padding: 0px 8px;
  background: white;
}
.filter-submit-button:focus {
  box-shadow: none;
}
.filter-submit-button:hover.green {
  background: rgba(0, 100, 0, 0.5);
}
.filter-submit-button:hover.blue {
  background: rgba(0, 102, 204, 0.2);
}
.filter-submit-button:hover.red {
  background: rgba(139, 0, 0, 0.5);
}
.filter-submit-button:hover.disabled {
  cursor: not-allowed;
}

.latest-button {
  border: 1px solid #222;
  border-radius: 5px;
  padding: 0px 8px;
  background: #DDD;
  color: #222;
}
.latest-button:hover {
  border: 1px solid #0066CC;
  background: #EEE;
  color: #222;
}
.latest-button:focus {
  box-shadow: none;
}
.latest-button:active {
  box-shadow: none;
  background: #EEE;
  color: #222;
}
.latest-button.selected {
  border: 1px solid #0066CC;
}
.latest-button.green {
  background: rgba(0, 100, 0, 0.3);
}
.latest-button.red {
  background: rgba(139, 0, 0, 0.3);
}
.latest-button.red:hover {
  background: rgba(139, 0, 0, 0.5);
}
.latest-button.disabled {
  background: rgba(167, 0, 0, 0.15);
  pointer-events: none;
}

.cbb-toggle-button {
  margin-top: 3px;
  margin-right: 3px;
  width: max-content;
  cursor: pointer;
  background: white;
  border: 1px solid #222222;
  border-radius: 5px;
  padding: 0px 6px;
  color: #222222;
  font-size: 0.9em;
}
.cbb-toggle-button.absolute-top-right {
  position: absolute;
  top: -20px;
  right: 0;
}
.cbb-toggle-button.disabled {
  background: rgba(167, 0, 0, 0.15);
  pointer-events: none;
}
.cbb-toggle-button:hover {
  background: #eeeeee;
}

.cbb-icon-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #222222;
  background: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.cbb-icon-button:hover {
  background: #dddddd;
}
.cbb-icon-button span {
  padding-bottom: 2px;
}
.cbb-icon-button.disabled {
  background: rgba(167, 0, 0, 0.15);
}

.cbb-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  max-height: 44px;
  line-height: 1.1;
  font-size: 0.98;
  cursor: pointer;
  background: #0066CC;
  border-radius: 5px;
  padding: 0px 15px;
  color: white;
}
.cbb-submit-button:hover {
  color: white;
  background: #0052a5;
}
.cbb-submit-button.disabled {
  background: rgb(167, 40, 40);
  pointer-events: none;
}
.cbb-submit-button.faded {
  opacity: 0.6;
  pointer-events: none;
}
.cbb-submit-button.dark-red {
  background: rgb(167, 40, 40);
}
.cbb-submit-button.dark-red:hover {
  background: rgb(140, 10, 10);
}
.cbb-submit-button.small {
  min-height: 30px;
  max-height: 30px;
  font-size: 0.95em;
  padding: 0px 6px;
}

.cbb-print-button {
  cursor: pointer;
  margin-top: 3px;
  padding: 4px;
  height: 36px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid #222222;
}
@media (max-width: 992px) {
  .cbb-print-button {
    height: 29px;
    width: 28px;
    padding-left: 2px;
    padding-top: 1px;
  }
}
.cbb-print-button svg {
  pointer-events: none;
  height: 25px;
  width: 25px;
}
@media (max-width: 992px) {
  .cbb-print-button svg {
    height: 22px;
    width: 22px;
  }
}
.cbb-print-button:hover {
  background-color: #dddddd;
}
@media (max-width: 575.98px) {
  .cbb-print-button {
    display: none;
  }
}

.download-data-grey-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  margin-left: 7px;
  border-radius: 50%;
  border: 1px solid #222;
  display: inline-flex;
  cursor: pointer;
}
.download-data-grey-button:hover {
  background-color: #dddddd;
}
.download-data-grey-button a {
  margin-top: -5px;
}

div.not-subscribed-box {
  background-color: #eeeeee;
  color: #222222;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.16);
  border: 2px solid #0066CC;
  border-radius: 5px;
  padding: 15px 20px;
  max-width: 875px;
  line-height: 1.4;
}
div.not-subscribed-box p.header-text {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.15em;
}
div.not-subscribed-box li {
  font-size: 1.05em;
}
div.not-subscribed-box span.body-text {
  margin-top: 15px;
  font-size: 1.05em;
  padding-right: 4px;
}

.row.primary-header {
  display: flex;
  align-items: baseline;
  font-size: 38px;
  color: #333333;
  padding: 0px 0px 4px 60px;
  margin: 0px 0px 0px -60px;
  background: linear-gradient(to top right, transparent 49%, #0066CC 50%) left 40px bottom 5px/6px 10px, linear-gradient(to top left, transparent 49%, #0066CC 50%) left 46px bottom 5px/6px 10px, linear-gradient(to bottom right, transparent 49%, #0066CC 50%) left 40px bottom 15px/6px 10px, linear-gradient(to bottom left, transparent 49%, #0066CC 50%) left 46px bottom 15px/6px 10px, linear-gradient(currentColor, currentColor) left 60px bottom 13px/100% 2px;
  background-repeat: no-repeat;
}
.row.primary-header p {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
}
.row.primary-header p span {
  font-size: 26px;
}
.row.primary-header span {
  padding-left: 7px;
  font-size: 26px;
  font-weight: 700;
}
.row.primary-header span.span1 {
  padding-left: 7px;
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: 700;
}
.row.primary-header span.span2 {
  padding-left: 7px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 300;
}

a.cbb-brand-link, span.cbb-blue-span {
  cursor: pointer;
  color: #0066CC;
  font-weight: 700;
  padding-left: 3px;
}

span.cbb-span {
  cursor: pointer;
  color: #0066CC;
}
span.cbb-span.bold {
  font-weight: 700;
}
span.cbb-span:hover {
  text-decoration: underline;
}

a.no-hover-line {
  text-decoration: none;
}
a.no-hover-line:hover {
  text-decoration: none;
}

.modal-input, .annotations-input {
  max-width: 375px;
  margin-bottom: 2px;
  max-height: 34px;
  color: #222222;
  font-size: 0.95em;
  border: 1px solid #777777;
}
.modal-input::placeholder, .annotations-input::placeholder {
  color: #555555;
  font-size: 0.95em;
}
.modal-input:focus, .annotations-input:focus {
  border: none;
  box-shadow: 0 0 0 0.12rem rgba(0, 120, 240, 0.9);
}
.modal-input.no-style, .annotations-input.no-style {
  background: none;
  padding: 0;
  border: none;
  height: auto;
  max-width: 100%;
}
.modal-input.no-style:focus, .annotations-input.no-style:focus {
  border: none;
  box-shadow: none;
}

.home-page-header {
  font-family: "Roboto Slab";
  font-weight: 700;
  color: #0066CC;
  text-align: left;
  margin: 0 0 40px 0;
  padding: 0;
  font-size: calc(28px + 1.8vw);
}

.number-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px 0px;
  background: #fff;
  border: 2px solid #333333;
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
}

button.info-button {
  height: 17px;
  width: 17px;
  padding: 0;
  border-radius: 9px;
  border: 1px solid #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}
button.info-button:hover {
  outline: none;
  background: #bbbbbb;
}
button.info-button span {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 14px;
  font-weight: 700;
  color: white;
  pointer-events: none;
}

.global-info-modal, .print-info-modal {
  z-index: 999;
  top: 0;
  left: -405px;
  background: #fff;
  position: absolute;
  max-width: 475px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.425), 0 1px 5px 0 rgba(0, 0, 0, 0.425), 0 3px 1px -2px rgba(0, 0, 0, 0.425);
  opacity: 1;
}
.global-info-modal.show-modal, .print-info-modal.show-modal {
  display: block;
  opacity: 1;
}
.global-info-modal.hide-modal, .print-info-modal.hide-modal {
  display: none;
  opacity: 0;
}
.global-info-modal .info-modal-header, .print-info-modal .info-modal-header {
  padding: 9px 0px 2px 0px;
  background: #DDD;
  border-bottom: 1px solid #BBB;
}
.global-info-modal .info-modal-header h3.header, .print-info-modal .info-modal-header h3.header {
  margin: 0px 10px;
  font-size: 1.2em;
  font-weight: 700;
}
.global-info-modal .body, .print-info-modal .body {
  font-size: 0.85em;
  padding: 8px 10px 2px 10px;
  line-height: 1.25;
  background: #FFF;
}
.global-info-modal .body .content, .print-info-modal .body .content {
  padding-bottom: 8px;
  display: none;
}
.global-info-modal p.title, .print-info-modal p.title {
  display: inline;
  font-weight: 700;
  padding-right: 4px;
}
.global-info-modal p.desc, .print-info-modal p.desc {
  display: inline;
}

.signup-button {
  background: #0066CC;
}
.signup-button.disabled {
  background: rgb(167, 40, 40);
  cursor: not-allowed;
}
.signup-button.disabled:hover {
  background: rgb(140, 10, 10);
}
.signup-button:hover {
  background: #0052a5;
}

.button-group {
  background-color: #fff;
  color: #000;
  border: 1px solid #e3e3e3;
  padding: 3px 9px;
  margin-right: 3px;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  line-height: 1.5;
  outline: none !important;
  -webkit-transition: background-color 0.35s;
  transition: background-color 0.35s;
}
.button-group.selected {
  background-color: #0066cc;
  color: #fff;
}

.animate-fade-away {
  animation: fadeAnimation 8s ease-in 0.1s forwards;
}

.animate-fade-away-4s {
  animation: fadeAnimation 4s ease-in 0.1s forwards;
}

.animate-fade-away-20s {
  animation: fadeAnimation 20s ease-in 0.1s forwards;
}

.cbb-button {
  background: white;
  margin: 3px 3px 0 0;
  border-radius: 5px;
  border: 1px solid #222222;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  line-height: 1;
  cursor: pointer;
  height: 36px;
}
.cbb-button:hover {
  background: #DDDDDD;
}
.cbb-button.selected {
  background: rgba(0, 102, 204, 0.4);
  border: 1px solid #222;
}
.cbb-button.disabled {
  background: rgba(167, 0, 0, 0.15);
  pointer-events: none;
}
.cbb-button.cbb-button-small {
  margin: 2px 2px 0 0;
  padding: 3px 5px;
  height: 28px;
  font-size: 0.925em;
}
.cbb-button.grey {
  background: #CCCCCC;
}

.cba-button-group {
  display: flex;
}
.cba-button-group .cba-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 3px 0 0;
  border-radius: 5px;
  border: 1px solid #222222;
  padding: 5px 10px;
  min-width: 90px;
  line-height: 1;
  cursor: pointer;
  height: 36px;
  font-size: 1.05em;
  color: #222;
}
.cba-button-group .cba-button:hover {
  background: #DDDDDD;
}
.cba-button-group .cba-button.resubmit {
  background: rgba(0, 102, 204, 0.2);
}
.cba-button-group .cba-button.resubmit:hover {
  background: rgba(0, 102, 204, 0.6);
}
.cba-button-group .cba-button.disabled {
  color: #222;
  background: rgba(167, 0, 0, 0.15);
  pointer-events: none;
}
.cba-button-group .cba-button.selected {
  background: rgba(0, 102, 204, 0.4);
}
.cba-button-group .cba-button.selected.disabled {
  background: rgba(0, 30, 204, 0.4);
}
.cba-button-group .cba-button.small {
  margin: 2px 2px 0 0;
  padding: 3px 5px;
  height: 28px;
  font-size: 0.925em;
}

button.stripe-button {
  margin: 0;
  border: 1px solid #222222;
  border-radius: 5px;
  text-align: center;
  padding: 8px 8px;
  color: white;
  font-weight: 700;
  width: 100%;
  background: #0066CC;
}
button.stripe-button:disabled {
  opacity: 0.75;
  cursor: not-allowed;
}
button.stripe-button:disabled.green {
  background: darkgreen;
}
button.stripe-button:disabled.grey {
  background: #888888;
}
button.stripe-button.blue {
  background: rgb(0, 0, 139);
}
button.stripe-button.blue:hover {
  background: rgb(0, 0, 115);
}
button.stripe-button.red {
  background: rgb(139, 0, 0);
}
button.stripe-button.red:hover {
  background: rgb(115, 0, 0);
}

.filter-circle-button {
  position: absolute;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #666;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #eee;
  border: 1px solid #ccc;
  z-index: 100;
}
.filter-circle-button.x-button {
  left: -8px;
  top: -8px;
  background: darkred;
  color: white;
  opacity: 0.75;
}
.filter-circle-button.x-button:hover {
  opacity: 1;
}
.filter-circle-button.plus-button {
  right: -8px;
  top: -8px;
  background: darkgreen;
  color: white;
  opacity: 0.75;
}
.filter-circle-button.plus-button:hover {
  opacity: 1;
}

.marker-hidden {
  opacity: 0.075;
}

.faq-text {
  font-size: 1.1em;
}

div.section-info-text-body {
  height: auto;
  font-size: 1.05em;
  line-height: 1.3;
  max-width: 1620px;
  margin: 0 auto;
  color: #333;
}
div.section-info-text-body.hide {
  display: none;
  height: 0px;
}
div.section-info-text-body p.text-item {
  margin-left: 15px;
  margin-bottom: 6px;
}
div.section-info-text-body p.bullet-item {
  margin-left: 32px;
  margin-bottom: 6px;
}

div.select-saver {
  display: flex;
}
div.select-saver.display-none {
  display: none;
}
div.select-saver .toggle-button {
  height: 11px;
  width: 11px;
  background: rgba(0, 102, 204, 0.6);
  border: 1px solid #222;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
}
div.select-saver .toggle-button:hover {
  background: rgb(0, 102, 204);
}

.save-button {
  height: fit-content;
  font-size: 0.75em;
  font-weight: 700;
  margin: 3px 3px 0px 0px;
  padding: 0px 3px;
  cursor: pointer;
  border: 1px solid #222;
  border-radius: 5px;
  line-height: 1.4;
  background: #EEE;
}
.save-button:hover {
  background: #AAA;
}
.save-button.disabled {
  pointer-events: none;
  background: #999;
}

p.green-explainer {
  font-size: 1.1em;
  font-weight: 700;
  color: darkgreen;
  line-height: 1.2;
}
p.green-explainer span {
  font-weight: 300;
}
p.green-explainer.mt3 {
  margin-top: 3px;
}
p.green-explainer.mt5 {
  margin-top: 5px;
}

p.red-explainer {
  font-size: 1.1em;
  font-weight: 700;
  color: darkred;
  line-height: 1.2;
}

.rc-input-number.cbb-input .rc-input-number-input-wrap .rc-input-number-input {
  width: 45px;
  padding-left: 4px;
  border: 1px solid #333;
  border-radius: 5px;
  height: 36px;
}

.rc-input-number.cbb-input-small .rc-input-number-input-wrap .rc-input-number-input {
  width: 45px;
  padding-left: 4px;
  border: 1px solid #333;
  border-radius: 5px;
}

.rc-input-number.cbb-input-small.disabled .rc-input-number-input-wrap .rc-input-number-input {
  background: rgba(167, 0, 0, 0.15);
}

.rc-input-number.cbb-input-scalable .rc-input-number-input-wrap {
  width: 100%;
  height: 100%;
}
.rc-input-number.cbb-input-scalable .rc-input-number-input-wrap .rc-input-number-input {
  width: 100%;
  height: 100%;
  padding-left: 4px;
  border: 1px solid #333;
  border-radius: 5px;
}

.rc-input-number.filter-input {
  z-index: 1;
}
.rc-input-number.filter-input:focus {
  z-index: 100;
}
.rc-input-number.filter-input .rc-input-number-input-wrap .rc-input-number-input {
  height: 36px;
  width: 60px;
  border: 1px solid #888;
  border-radius: 0px;
  padding-left: 5px;
  background: #DDD;
}
.rc-input-number.filter-input .rc-input-number-input-wrap .rc-input-number-input:focus {
  border: 1px solid #0066CC;
}
.rc-input-number.filter-input .rc-input-number-input-wrap .rc-input-number-input:focus {
  box-shadow: 0 0 1px #0066CC;
}
.rc-input-number.filter-input .rc-input-number-input-wrap .rc-input-number-input::placeholder {
  font-size: 1em;
  color: #222;
}
.rc-input-number.filter-input .rc-input-number-input-wrap .rc-input-number-input:hover {
  background: #EEE;
}
.rc-input-number.filter-input.white .rc-input-number-input-wrap .rc-input-number-input {
  background: white;
}

.pct-input {
  position: relative;
}
.pct-input .rc-input-number-input-wrap .rc-input-number-input {
  padding-right: 10px;
}
.pct-input::after {
  content: "%";
  position: absolute;
  left: calc(100% - 15px);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #222;
}

.rc-input-number.cbb-cleaner-input.disabled .rc-input-number-input-wrap .rc-input-number-input {
  pointer-events: none;
  background: rgba(167, 0, 0, 0.15);
}
.rc-input-number.cbb-cleaner-input .rc-input-number-input-wrap .rc-input-number-input {
  height: 36px;
  width: 70px;
  border: 1px solid #333;
  border-radius: 5px;
  padding-left: 5px;
}
.rc-input-number.cbb-cleaner-input .rc-input-number-input-wrap .rc-input-number-input::placeholder {
  font-size: 1.05em;
  color: #222;
}
.rc-input-number.cbb-cleaner-input .rc-input-number-input-wrap .rc-input-number-input:hover {
  background: #EEE;
}

.cbb-multi-select-wrapper .css-1tb7n32-control {
  height: 36px;
  overflow: hidden;
}
.cbb-multi-select-wrapper .css-1tb7n32-control .css-12wzxpg-IndicatorsContainer {
  height: 36px;
  align-self: flex-end;
}

.player-banner-row {
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  margin-top: 0px;
  color: white;
}

.blog-post-container {
  max-width: 1200px;
  margin-top: 10px;
}
.blog-post-container li {
  line-height: 1.45;
}
.blog-post-container p {
  font-size: 1.05em;
}
.blog-post-container h3.blog-header {
  font-size: 1.7em;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #0066CC;
  margin-top: 30px;
}

a.blog-link {
  color: #0066CC;
  text-decoration: underline;
}

button.blog-header-button {
  border: none;
  background: none;
  border-radius: 5px;
  margin-left: -5px;
}
button.blog-header-button:hover {
  background: #DDD;
}

button.blog-more-button {
  border: none;
  background: none;
  color: #0066CC;
  font-style: italic;
}

button.blog-home-button {
  color: #0066CC;
  text-decoration: underline;
  border-radius: 5px;
  padding: 1px 3px;
  border: 1px solid #888;
  background: rgba(0, 102, 204, 0.15);
}
button.blog-home-button:hover {
  background: rgba(0, 102, 204, 0.3);
}

p.blog-authors {
  margin-top: 3px;
  color: #444444;
  text-align: center;
  font-weight: 700;
  font-size: 0.95em;
}

.myaccount-button {
  border: 1px solid #222;
  padding: 1px 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin-right: 3px;
  max-width: 250px;
  min-width: 100px;
}
.myaccount-button:hover {
  background: #BBB;
  border: 1px solid #222;
}
.myaccount-button.selected {
  background: rgba(0, 102, 204, 0.4);
  border: 1px solid #222;
}
.myaccount-button.disabled {
  background: rgba(167, 0, 0, 0.15);
  pointer-events: none;
  border: 1px solid #222;
}

div.tools-card {
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 15px;
  padding: 12px;
  -webkit-filter: brightness(100%);
}
div.tools-card:hover {
  -webkit-filter: brightness(75%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
div.tools-card div.tools-card-image-wrapper {
  margin-top: 0px;
  border-radius: 12px;
  background: #eee;
  aspect-ratio: 1.625;
}
div.tools-card div.tools-card-image-wrapper img {
  width: 100%;
  border-radius: 12px;
}
div.tools-card span.tools-card-header {
  font-family: "Roboto Slab";
  font-weight: 700;
  font-size: 1.2em;
  text-align: left;
  line-height: 1.1;
  border-bottom: 2px solid #0066CC;
}
div.tools-card span.tools-card-header.no-border {
  border-bottom: none;
}
div.tools-card span.tools-card-sub-header {
  font-size: 1em;
}
div.tools-card.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.big-ten-styles {
  position: absolute;
  display: flex;
  background-image: url("https://storage.googleapis.com/cbb-image-files/cbb-misc/bigten-shotchart-background.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

@media (max-width: 767.98px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile-show {
    display: none;
  }
}

.hover-images-container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 16px;
  cursor: default;
}

.hover-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.hover-container .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  justify-content: space-between;
  text-align: left;
  padding: 16px 20px;
  color: white;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content-quote {
  font-size: 16px;
  font-style: italic;
}

.content-name {
  font-weight: bold;
}

.hover-container:hover .content-overlay {
  opacity: 1;
}

.hover-container:hover .content-details {
  opacity: 1;
}

.home-page-signup-card-container {
  min-height: 420px;
  background: white;
  border-radius: 10px;
  text-align: center;
  padding: 30px;
}
.home-page-signup-card-container .sign-up-card-title {
  color: #0066CC;
  letter-spacing: 0;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 34px;
  line-height: 34px;
}
.home-page-signup-card-container .sign-up-card-description {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.34px;
  margin-bottom: 24px;
}
.home-page-signup-card-container .sign-up-card-bullet-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  padding: 0;
  margin-bottom: 24px;
}
.home-page-signup-card-container .sign-up-card-bullet-list-item {
  display: flex;
  align-items: center;
}
.home-page-signup-card-container .override-bullet-point {
  display: inline-block;
  height: 5px;
  width: 5px;
  margin-right: 8px;
  border-radius: 50%;
  background: #0066CC 0% 0% no-repeat padding-box;
}
.home-page-signup-card-container .sign-up-card-price-container {
  display: "flex";
  margin-bottom: 40px;
}
.home-page-signup-card-container .sign-up-card-price-string {
  letter-spacing: 0;
  font-size: 35px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
}
.home-page-signup-card-container .sign-up-card-button {
  background: #C75300;
  border: none;
  border-radius: 3px;
  height: 46px;
  max-width: 100%;
  width: 258px;
  font-size: 18px;
  color: white;
  text-align: center;
  font-weight: 700;
}
.home-page-signup-card-container .sign-up-card-button:hover {
  background: #B35900;
}

.hex-logo-wrapper {
  margin-top: 45px;
  width: 161px;
  height: 90px;
  background-color: white;
  border-color: white;
  position: relative;
  display: inline-block;
  z-index: 0;
  opacity: 0.15;
}

.hex-logo-wrapper:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 45px solid;
  border-color: inherit;
  border-left: 82px solid transparent;
  border-right: 80px solid transparent;
  position: absolute;
  top: -44px;
}

.hex-logo-wrapper:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -44px;
  border-top: 45px solid;
  border-color: inherit;
  border-left: 83px solid transparent;
  border-right: 80px solid transparent;
  left: 0;
  z-index: -1;
}

div.table-of-contents .entry {
  font-size: 24px;
  font-weight: 700;
  display: grid;
  margin-top: 25px;
  grid-template-columns: auto max-content;
  grid-template-areas: "chapter page";
  align-items: end;
  gap: 0 0.25rem; /* customize the gap to your needs */
}
div.table-of-contents .entry.sub-entry {
  margin-left: 40px;
  font-weight: 300;
  font-size: 20px;
  margin-top: 3px;
}
div.table-of-contents .chapter {
  grid-area: chapter;
  position: relative;
  overflow: hidden;
}
div.table-of-contents .chapter::after {
  position: absolute;
  padding-left: 0.25ch; /* customise the padding to your needs */
  /* adjust the amount of dots to your needs */
  content: " . . . . . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . . . . ";
  text-align: right; /* optional, can be removed */
}
div.table-of-contents .page {
  grid-area: page;
}

.adsbygoogle:empty {
  display: none;
}

.adsbygoogle[data-ad-status=unfilled] {
  display: none;
}

.hide-non-print {
  display: none;
}

@media (max-width: 768px) {
  #hide-mobile, .hide-mobile {
    display: none;
  }
}

.print-break {
  page-break-before: always;
  margin-top: 10px;
}

@media print {
  .row.hide-non-print {
    display: flex;
  }
  @page {
    margin: 0;
    size: A4 landscape !important;
  }
  .mr-n30 {
    margin-left: -30px;
  }
  .mr-n40 {
    margin-left: -40px;
  }
  .mr-n60 {
    margin-left: -60px;
  }
  .mr-n80 {
    margin-left: -80px;
  }
  .ml-40 {
    margin-left: 40px;
  }
  .ml-n10 {
    margin-left: -10px;
  }
  .pl-4pct {
    padding-left: 4.5%;
  }
  .hide-non-print {
    display: block;
  }
  span.hide-non-print {
    display: inline;
  }
  .hide-print {
    display: none !important;
  }
  a {
    text-decoration: none;
  }
  .print-border {
    border: 1px solid #999;
  }
  .max-100-pct {
    max-width: 100%;
  }
  .max-67-pct {
    max-width: 67%;
  }
  .max-66-pct {
    max-width: 66%;
  }
  .max-50-pct {
    max-width: 50%;
  }
  .max-40-pct {
    max-width: 40%;
  }
  .max-33-pct {
    max-width: 33%;
  }
  .max-25-pct {
    max-width: 25%;
  }
  .print-33-pct {
    max-width: 33%;
    flex: auto;
  }
  .print-50-pct {
    max-width: 50%;
    flex: auto;
  }
  .print-67-pct {
    max-width: 67%;
    flex: auto;
  }
  .pct-100 {
    width: 100%;
  }
  .shotchart-print-adj {
    padding-right: 60px;
  }
  .cbb-select {
    display: none;
  }
  .cbb-button {
    display: none;
  }
  div.select-saver {
    display: none;
  }
  .cbb-print-button {
    display: none;
  }
  .app-footer-container {
    display: none;
  }
  .mini-game-card-info {
    display: none;
  }
  .cbb-box-shadowed {
    border: 1px solid #999;
    background: #FAFAFA;
  }
  .graph-grid-2x2 {
    margin-left: 0;
  }
  .mini-game-card-navbar-wrapper {
    padding: 0px 20px 0px 20px;
  }
  .mini-game-card-navbar {
    max-height: 29px;
    min-height: 29px;
  }
  .mini-game-card-navbar a.mini-game-card {
    max-height: 29px;
    margin: 0px 0px 0px 0px;
    border-left: 1px solid #222222;
    text-decoration: none;
  }
  .mini-game-card-navbar a.mini-game-card span {
    line-height: 1.2;
    font-size: 9px;
  }
  .mini-game-card-navbar a.mini-game-card .card-col-1 {
    padding: 1px;
    padding-bottom: 0px;
  }
  .mini-game-card-navbar a.mini-game-card .card-col-1 img {
    max-height: 16px;
    max-width: 16px;
  }
  button.info-button {
    width: 14px;
    height: 13px;
    padding: 0;
  }
  button.info-button span {
    font-size: 12px;
  }
  .game-header, .game-score {
    font-size: calc(11px + 1.2vw);
    line-height: 1.05;
  }
  .shadow-container {
    padding-bottom: 0px;
  }
  .padded-inner-container {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .graph-header {
    font-size: 1.05em;
  }
  .section-header-1 {
    font-size: 25px;
    line-height: 0.9;
  }
  .section-header-1.sm {
    font-size: 20px;
    line-height: 0.9;
  }
  .table-header-1 {
    font-size: 19px;
  }
  .table-header-1 span {
    font-size: 16px;
  }
  .logo-sticker {
    height: 49px;
    width: 49px;
  }
  .player-banner-row {
    width: 103%;
    margin-top: 0px;
  }
  .controller-navbar-container {
    width: 100.25%;
    min-height: 44px;
    position: static;
    top: 0px;
    border-top: 1px solid #222222;
  }
  .controller-navbar-container div.header-wrapper {
    flex-direction: row;
    align-items: end;
  }
  .controller-navbar-container .header {
    font-size: calc(11px + 1.2vw);
    margin-bottom: 2px;
  }
  .controller-navbar-container .win-loss-label {
    margin-left: 15px;
    font-size: calc(9px + 0.7vw);
    margin-bottom: 2px;
    padding-bottom: 0px;
  }
  .controller-navbar-container .buttons {
    display: none;
  }
  .controller-navbar-container div.buttons {
    display: none;
  }
  .controller-navbar-container div.buttons.game-buttons {
    display: none;
  }
  .controller-navbar-container div.buttons .button {
    display: none;
  }
  .controller-navbar-container .game-header, .controller-navbar-container .game-score, .controller-navbar-container .game-record, .controller-navbar-container .game-date {
    margin-bottom: 1px;
  }
  .controller-navbar-container .game-header {
    font-size: calc(10px + 0.8vw);
    margin-top: 1px;
  }
  .controller-navbar-container .game-score {
    font-size: calc(14px + 1.4vw);
  }
  .controller-navbar-container .game-record {
    font-size: calc(10px + 0.5vw);
  }
  div.logos-wrapper {
    padding: 1px 5px;
  }
  div.logos-wrapper div.logo-sticker {
    height: 45px;
    max-height: 45px;
    width: 45px;
    max-width: 45px;
  }
  .print-break {
    page-break-before: always;
    margin-top: 10px;
  }
  .top-navbar {
    display: none;
    position: static;
    height: 0;
  }
}

